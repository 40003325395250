import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Card from "../card/index.js";
import { NS_LOGIN_API } from "../../modules/static/api.js";
import ScrollToTop from "../../components/scrollToTop";
import {
  chunks,
  flatten,
  queryCheck,
  getParameterByName,
  decodeHtmlContent,
} from "../../utility/utility";
import { translateToEnglish } from '../../utility/translateHelper';
import { history } from "../../utility/store";
import LoadingPage from "../../components/loadingPage";
import LoadingGuage from "../../components/loadingGuage";
import Navi from "../../components/navi";
import SubNavi from "../../components/subNavi";
import Guideline from "../../components/guideline";
import {
  SalesHeader,
  CheerHeader,
  NewCheerHeader,
  NewCheerHeader2,
  CheerFashionHeader,
  PHeader,
  PentagonHeader,
  ChristmasHeader,
  StockBookHeader,
  CustomHeader,
} from "../../components/header";
import InfiniteScroll from "react-infinite-scroller";
import "./catalogue.css";
import {
  loadMoreProducts,
  loadAllProducts,
  getProductDataFromAPI,
  updateFilteredProducts,
  updateProductData,
  sortItems,
  selectProductsForPrint,
  changeSelectedCardPosition,
  handleTextChange,
  selectBreakPointForPrint,
  deselectProductsForPrint,
  deselectBreakPointForPrint,
  selectSmallerText,
  deselectSmallerText,
  putInCart,
  clearSearchFilters,
  clearSelecitons,
  selectAllFilteredItems,
} from "../../modules/product";

class Catalogue extends Component {
  constructor(props) {
    super(props);
    this.createCard = this.createCard.bind(this);
    this.prepareForPrint = this.prepareForPrint.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
    this.detectImgLoaded = this.detectImgLoaded.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openGuide = this.openGuide.bind(this);
    this.closeGuide = this.closeGuide.bind(this);
    this.getHeader = this.getHeader.bind(this);
    this.hideStockInfo = this.hideStockInfo.bind(this);
    this.hidePriceInfo = this.hidePriceInfo.bind(this);
    this.state = {
      pageNumber:
        this.props.match.params.brand === "current_catalog" ||
        this.props.match.params.brand === "cardType3"
          ? 4
          : 12,
      rowNumber: 4,
      selectedCardViewMode: false,
      numOfImgLoaded: 0,
      showLoadingPartial: false,
      printMode: false,
      selectedPrintMode: false,
      breakLineSelected: false,
      fixHeaderOn: false,
      brand: this.props.match.params.brand,
      hasLoadingError: false,
      catalogueName: `${this.props.match.params.brand}_sales`,
      modalDetailOpened: false,
      modalActive: true,
      hideStock: false,
      hidePrice: false,
      details: {},
      lang: "ja",
    };
  }

  componentDidMount() {
    document.title = `${this.state.brand} カタログ`;

    switch (this.state.brand) {
      case "cheer":
        document.title = "cheer SALE";
        window.location = window.location.href.replace("cheer", "newcheer2");
        break;
      case "newcheer":
        document.title = "cheer BOOK";
        break;
      case "newcheer2":
        document.title = "cheer SALE";
        break;
      case "cheerfashion":
        document.title = "cheer CLOTHES";
        break;
      case "stockbook":
        document.title = "Stock Book";
        break;
      case "netsuite":
        document.title = "全商品";
        break;
      case "current_catalog":
        document.title = "現行カタログ";
        break;
      default:
        document.title = "Stock Book";
        break;
    }

    let innerWidth = window.innerWidth;
    let fixHeaderActivatePos;
    if (this.state.brand === "cheer") {
      fixHeaderActivatePos = innerWidth <= 1150 ? 170 : 140;
    } else {
      fixHeaderActivatePos = innerWidth <= 1150 ? 125 : 200;
    }

    if (innerWidth <= 767) {
      this.setState({ modalActive: false });
    }

    document.addEventListener("scroll", () => {
      if (window.scrollY >= fixHeaderActivatePos) {
        if (!this.state.fixHeaderOn) this.setState({ fixHeaderOn: true });
      } else {
        if (this.state.fixHeaderOn) this.setState({ fixHeaderOn: false });
      }
    });

    let code, custitem_ns_komida;
    if (
      this.state.brand === "netsuite" ||
      this.state.brand === "current_catalog"
    ) {
      localStorage.setItem("catalogType", this.state.brand);
      code = getParameterByName("code");
      custitem_ns_komida = getParameterByName("custitem_ns_komida");
      if (custitem_ns_komida) {
        localStorage.setItem(
          "custitem_ns_komida",
          encodeURIComponent(custitem_ns_komida)
        );
      }

      if (!code) {
        window.location.href = NS_LOGIN_API;
      }
    } else {
      localStorage.removeItem("catalogType");
    }

    if (this.state.brand !== "current_catalog") {
      localStorage.removeItem("custitem_ns_komida");
    }

    this.props
      .getProductDataFromAPI(
        this.state.catalogueName,
        code,
        localStorage.getItem("custitem_ns_komida")
      )
      .then((data) => {
        history.location.search = history.location.search.replace("code=", "");
        history.push(history.location);
        this.setState({ showLoadingPartial: true });
      });
  }

  componentDidUpdate() {
    let allItems = document.querySelectorAll("img.card-img");
    let selectedItems = document.querySelectorAll(
      ".selected-for-print .card-img.loaded"
    );
    let loadedItemLength =
      selectedItems.length > 0
        ? selectedItems.length
        : this.state.numOfImgLoaded;
    if (this.state.printMode && loadedItemLength >= allItems.length) {
      this.setState({
        printMode: false,
        selectedPrintMode: false,
      });
      window.print();
    }
  }

  toggleViewMode() {
    this.setState({ selectedCardViewMode: !this.state.selectedCardViewMode });
  }

  updateLang(value) {
    this.setState({ lang: value });
  }

  downloadCSV() {
    let targetContent = this.props.filteredProducts;
    if (this.props.selectedProductsForPrint.length > 0) {
      targetContent = this.props.selectedProductsForPrint;
    }

    let header = Object.keys(targetContent[0]);
    let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
    if (header[0] === "parentId") {
      csvContent += header.slice(1).join(",") + "\n";
      csvContent += targetContent
        .map((e) =>
          Object.values(e)
            .slice(1)
            .join(",")
        )
        .join("\n");
    } else {
      csvContent += header.join(",") + "\n";
      csvContent += targetContent
        .map((e) => Object.values(e).join(","))
        .join("\n");
    }

    let link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  prepareForPrint() {
    let selectedItems = document.querySelectorAll(".card.selected-for-print");
    this.setState({ printMode: true });
    if (selectedItems.length > 0) {
      this.setState({ selectedPrintMode: true });
    } else {
      this.props.loadAllProducts();
    }
  }

  hideStockInfo() {
    this.setState({ hideStock: !this.state.hideStock });
  }

  hidePriceInfo() {
    this.setState({ hidePrice: !this.state.hidePrice });
  }

  detectImgLoaded(event) {
    let target = event.currentTarget ? event.currentTarget : event.srcElement;
    target.className += " loaded";
    let loadedItems = document.querySelectorAll("img.loaded");
    this.setState({
      numOfImgLoaded: loadedItems.length,
      hasLoadingError: event.hasError,
    });
  }

  createCard(data, type, checkMode, rIndex, pIndex) {
    console.log(data);
    return (
      <div
        key={data.productId + data.branchId + "" + rIndex + "" + pIndex}
        className={`card-wrapper col-sm-6 no-gutter-card-deck ${type} ${
          type === "type3" ? "" : "col-lg-3"
        } ${rIndex > 1 ? "card-row-second" : "card-row-first"} ${
          data.cloned ? "cloned" : ""
        }`}
      >
        <Card
          lang={this.state.lang}
          name={
            this.state.lang === "ja"
              ? decodeHtmlContent(data.name)
              : decodeHtmlContent(data.englishName)
          }
          cardType={type}
          imgUrl={data.imgUrl || ""}
          price={data.price || ""}
          adjustedPrice={data.adjustedPrice || ""}
          adjustDataFn={this.props.updateProductData}
          productId={data.productId || ""}
          branchId={data.branchId || ""}
          children={data.children || ""}
          catalogUnit={data.catalogUnit || ""}
          material={
            this.state.lang === "ja"
              ? decodeHtmlContent(data.material)
              : decodeHtmlContent(data.englishMaterial)
          }
          memo={
            this.state.lang === "ja"
              ? decodeHtmlContent(data.memo)
              : decodeHtmlContent(data.englishMemo)
          }
          info={
            this.state.lang === "ja"
              ? decodeHtmlContent(data.info)
              : decodeHtmlContent(data.englishInfo)
          }
          ct={data.ct || ""}
          viewMode={this.state.selectedCardViewMode}
          selected={data.selected || ""}
          breakpoint={data.breakpoint || ""}
          smallerText={data.smallerText || ""}
          size={
            this.state.lang === "ja"
              ? decodeHtmlContent(data.size)
              : decodeHtmlContent(data.englishSize)
          }
          unitSize={data.unitSize || ""}
          orderSize={data.orderSize || ""}
          stocks={data.stocks || ""}
          pageUrl={data.pageUrl || ""}
          branchNames={data.branchId || ""}
          deadline={data.deadline || ""}
          regular={data.regular || ""}
          imgLoadDetectFn={this.detectImgLoaded}
          patterns={data.pattern || ""}
          selectItemForPrintFn={this.props.selectProductsForPrint.bind(
            this,
            data
          )}
          changeSelectedCardPositionFn={this.props.changeSelectedCardPosition.bind(
            this,
            data
          )}
          selectBreakpointForPrintFn={this.props.selectBreakPointForPrint.bind(
            this,
            data
          )}
          deselectItemForPrintFn={this.props.deselectProductsForPrint.bind(
            this,
            data
          )}
          deselectBreakPointForPrintFn={this.props.deselectBreakPointForPrint.bind(
            this,
            data
          )}
          selectSmallerTextFn={this.props.selectSmallerText.bind(this, data)}
          deselectSmallerTextFn={this.props.deselectSmallerText.bind(
            this,
            data
          )}
          purchaseFn={this.props.putInCart.bind(this, data)}
          openDetailFn={this.openModal.bind(this, data)}
          handleChangeFn={this.props.handleTextChange.bind(this, data)}
          hideProductId={this.state.brand === "pentagon" ? true : false}
          checkMode={checkMode}
          customColor={data.customColor}
          hideStock={this.state.hideStock}
          hidePrice={this.state.hidePrice}
        />
      </div>
    );
  }

  openModal(data) {
    if (this.state.modalActive) {
      document.body.style.overflow = "hidden";
      let details = { name: data.name, bigImgUrl: data.bigImgUrl };
      this.setState({ modalDetailOpened: true, details });
    }
  }

  openGuide() {
    document.body.style.overflow = "hidden";
    this.setState({ modalGuideOpened: true });
  }

  closeGuide() {
    document.body.style.overflow = "auto";
    this.setState({ modalGuideOpened: false });
  }

  closeModal() {
    document.body.style.overflow = "auto";
    this.setState({ modalDetailOpened: false });
  }

  getHeader(brand, customHeader) {
    if (!customHeader["type"]) {
      switch (brand) {
        case "cheer":
          return <CheerHeader />;
        case "newcheer":
          return <NewCheerHeader />;
        case "newcheer2":
          return <NewCheerHeader2 />;
        case "cheerfashion":
          return <CheerFashionHeader />;
        case "pentagon":
          return <PentagonHeader />;
        case "christmas":
          return <ChristmasHeader />;
        case "p":
          return <PHeader />;
        case "stockbook":
          return <StockBookHeader />;
        case "book":
          return <StockBookHeader />;
        case "netsuite":
          return <StockBookHeader />;
        default:
          return <SalesHeader />;
      }
    } else {
      return (
        <CustomHeader
          type={customHeader["type"]}
          title={customHeader["title"]}
          titleColor={customHeader["titleColor"]}
          period={customHeader["period"]}
          periodColor={customHeader["periodColor"]}
          year={customHeader["year"]}
          yearColor={customHeader["yearColor"]}
          bgColor={customHeader["bgColor"]}
          optionalTitle={customHeader["optionalTitle"]}
          accentColor={customHeader["accentColor"]}
        />
      );
    }
  }

  render() {
    let products =
      this.state.selectedPrintMode || this.state.selectedCardViewMode
        ? this.props.selectedProductsForPrint
        : this.props.loadedProducts;

    products = products.map((p) => {
      let key = p.productId + p.branchId;
      p.selected = this.props.selectedProductsForPrint.some((sp) => {
        let skey = sp.productId + sp.branchId;
        return key === skey;
      });

      p.breakpoint = this.props.selectedBreakPointForPrint.some((sp) => {
        let skey = sp.productId + sp.branchId;
        return key === skey;
      });

      p.smallerText = this.props.selectedSmallerText.some((sp) => {
        let skey = sp.productId + sp.branchId;
        return key === skey;
      });
      return p;
    });

    let totalCard = products.length;
    let filteredProductLength =
      this.state.selectedPrintMode || this.state.selectedCardViewMode
        ? totalCard
        : this.props.filteredProductLength;
    let guageNum = this.state.selectedPrintMode
      ? totalCard
      : this.state.numOfImgLoaded;

    let clonedContent = Object.assign(
      { cloned: true, id: "cloned item" },
      products[0]
    );
    products.forEach((product, index) => {
      if (product.breakpoint) {
        let numOfInserts = 3 - (index % 4);
        for (var i = 1; i <= numOfInserts; i++) {
          products.splice(index + i, 0, clonedContent);
        }
      }
    });

    if (filteredProductLength % 4 > 0) {
      let remainingIndexForPage = 4 - (filteredProductLength % 4);
      for (var i = 1; i <= remainingIndexForPage; i++) {
        products.push(clonedContent);
      }
    }

    let rows = chunks(products, this.state.pageNumber, true);
    let pages = rows.map((page) => chunks(page, this.state.rowNumber));
    let fixHeaderClassName = this.state.fixHeaderOn ? "fixed" : "";
    let showMaintenance = "";
    let checkMode = "default";
    let hideFilter = false;
    let showHideStockBtn = false;
    let showHidePriceBtn = false;
    let showHideStockTypeBtn = false;
    let showCorporateName = false;
    let cardType = "default";
    let printType = "default";

    if (!queryCheck("skipMsg", "true") && this.props.maintenanceMode) {
      showMaintenance = "showMaintenance";
    }

    if (queryCheck("stockCheck", "true")) {
      checkMode = "stockCheck";
    }

    if (this.state.brand === "cheerfashion") {
      cardType = "type2";
    }

    if (this.state.brand === "stockbook") {
      cardType = "type2";
      printType = "headerless";
    }

    if (this.state.brand === "books") {
      cardType = "type2";
      printType = "headerless";
    }

    if (queryCheck("hideFilter", "true")) {
      hideFilter = true;
    }

    if (this.state.brand === "cardType2" || this.state.brand === "netsuite") {
      showHideStockBtn = true;
      showHidePriceBtn = true;
      showHideStockTypeBtn = true;
      // showCorporateName = true;
    }

    if (
      this.state.brand === "cardType3" ||
      this.state.brand === "current_catalog"
    ) {
      cardType = "type3";
    }

    return (
      <div
        className={`${fixHeaderClassName} ${printType}-printStyle ${
          this.state.brand
        }-wrapper`}
      >
        <div className={`block-maintenance ${showMaintenance}`}>
          現在サイトはメンテナンス中です。多大なご迷惑をおかけし、誠に申し訳ございません。
        </div>
        {this.getHeader(this.state.brand, this.props.customHeader)}
        <Navi
          toggleViewMode={this.toggleViewMode.bind(this)}
          viewMode={this.state.selectedCardViewMode}
          hideFilter={hideFilter}
          hasLoadingError={this.state.hasLoadingError}
          colors={this.props.colors}
          categories={this.props.categories}
          filters={this.props.filters}
          filterDataFn={this.props.updateFilteredProducts}
          adjustDataFn={this.props.updateProductData}
          clearFilterFn={this.props.clearSearchFilters}
          printFn={this.prepareForPrint.bind(this)}
          csvFn={this.downloadCSV.bind(this)}
          selectedItemLength={this.props.selectedProductsForPrint.length}
          clearSelectedItemFn={this.props.clearSelecitons}
          selectAllFilteredItemFn={this.props.selectAllFilteredItems}
          showHideStockBtn={showHideStockBtn}
          showHidePriceBtn={showHidePriceBtn}
          updateLang={this.updateLang.bind(this)}
          showHideStockTypeBtn={showHideStockTypeBtn}
          hideStockInfo={this.hideStockInfo}
          hidePriceInfo={this.hidePriceInfo}
          hideStock={this.state.hideStock}
          hidePrice={this.state.hidePrice}
        />
        {!this.state.selectedCardViewMode && (
          <SubNavi
            numOfItems={this.props.filteredProductLength}
            sortItemFn={this.props.sortItems}
            cardType={cardType}
          />
        )}

        {this.state.modalActive ? (
          <Guideline
            modalOpen={this.state.modalGuideOpened}
            closeFn={this.closeGuide}
            className="modal-guideline"
            overlayClassName="modal-guideline-overlay"
          />
        ) : (
          ""
        )}
        <div className="content-block container">
          {this.state.showLoadingPartial ? "" : <LoadingPage />}
          {this.state.printMode ? (
            <LoadingGuage
              guage={guageNum}
              full={totalCard}
              cancel={() => this.setState({ printMode: false })}
            />
          ) : (
            ""
          )}
          <InfiniteScroll
            className={`infinite-wrapper ${cardType}`}
            pageStart={0}
            loadMore={this.props.loadMoreProducts.bind(this)}
            hasMore={
              !this.state.selectedCardViewMode &&
              filteredProductLength > totalCard
            }
            initialLoad={true}
          >
            {pages.map((page, pIndex) => (
              <div key={"page" + pIndex} className={`print-page ${cardType}`}>
                {cardType === "type3" ? (
                  <header className="print-header">
                    <p className="page-category">
                      {/* [{!!page[0][0] && page[0][0].category}] */}
                    </p>
                  </header>
                ) : (
                  <header className="print-header">
                    <p className="page-number">
                      {pIndex * this.state.pageNumber + 1} -{" "}
                      {pIndex * this.state.pageNumber + flatten(page).length} /{" "}
                      {totalCard}{" "}
                      {this.props.filters.category &&
                      !this.state.selectedPrintMode
                        ? `- ${this.state.lang === 'en' ? translateToEnglish(this.props.filters.category) : this.props.filters.category}`
                        : ""}
                      {showCorporateName ? " - SHISEI HANBAI Co.,Ltd " : ""}
                    </p>
                  </header>
                )}
                {page.map((row, rIndex) => (
                  <div
                    key={"page" + pIndex + "row" + rIndex}
                    className={`row ${cardType} card-container`}
                  >
                    {row.map((card, rIndex) =>
                      this.createCard(card, cardType, checkMode, rIndex, pIndex)
                    )}
                  </div>
                ))}
                {cardType === "type3" && (
                  <div
                    className={`print-bottom print-bottom-${
                      pIndex % 2 === 0 ? "even" : "odd"
                    }`}
                  >
                    {page[0][0]["currentCatalog"] &&
                      page[0][0]["currentCatalog"].includes("/") &&
                      page[0][0]["currentCatalog"].includes("-") &&
                      page[0][0]["currentCatalog"]
                        .replaceAll(/[a-zA-Z]/g, "/")
                        .split("/")[3]
                        .split("-")[0]}
                    {/* {pIndex < 9 ? "0" : ""}
                    {pIndex + 1} */}
                  </div>
                )}
              </div>
            ))}
          </InfiniteScroll>
        </div>
        <ScrollToTop scrollStepInPx="100" delayInMs="10" />
        <button className="btn btn-hint" onClick={this.openGuide}>
          <i className="fa fa-question icon-question" />
          <p className="text">サイトの使い方</p>
        </button>
      </div>
    );
  }
}

const mapStateToProps = ({ product }) => ({
  loadedProducts: product.loadedProducts,
  filteredProducts: product.filteredProducts,
  selectedProductsForPrint: product.selectedProductsForPrint,
  selectedBreakPointForPrint: product.selectedBreakPointForPrint,
  selectedSmallerText: product.selectedSmallerText,
  colors: product.colors,
  categories: product.categories,
  filters: product.filters,
  filteredProductLength: product.filteredProductLength,
  customHeader: product.customHeader,
  maintenanceMode: product.maintenanceMode,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadMoreProducts,
      loadAllProducts,
      getProductDataFromAPI,
      updateFilteredProducts,
      updateProductData,
      sortItems,
      clearSearchFilters,
      selectProductsForPrint,
      handleTextChange,
      selectBreakPointForPrint,
      deselectProductsForPrint,
      deselectBreakPointForPrint,
      selectSmallerText,
      deselectSmallerText,
      changeSelectedCardPosition,
      putInCart,
      clearSelecitons,
      selectAllFilteredItems,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Catalogue);
