const categoryTranslations = {
  '什器・ディスプレイ': 'Fixtures & Displays',
  '生活雑貨・日用品': 'Living Goods & Daily Essentials',
  'インテリアグリーン・ガーデン': 'Interior Green & Garden',
  'ファッション雑貨': 'Fashion Goods',
  'アクセサリー': 'Accessories',
  '食器・キッチン': 'Tableware & Kitchen',
  'テーマ': 'Theme',
  'ファッション': 'Fashion',
  'ライト・照明': 'Lights & Illumination',
  'インテリア雑貨': 'Interior Goods',
  'その他': 'Others',
  'ペット用品': 'Pet Supplies',
  'インテリアファブリック': 'Interior Fabrics',
  '家具': 'Furniture'
};

export const translateToEnglish = (text) => {
  if (!text) return '';
  return categoryTranslations[text] || text; // Returns original text if translation not found
};

export const translateToJapanese = (text) => {
  if (!text) return '';
  // Create reverse mapping
  const reverseTranslations = Object.entries(categoryTranslations).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});
  
  return reverseTranslations[text] || text; // Returns original text if translation not found
};

// Helper to get all categories in either language
export const getAllCategories = (language = 'ja') => {
  if (language === 'en') {
    return Object.values(categoryTranslations);
  }
  return Object.keys(categoryTranslations);
}; 